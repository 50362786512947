<template>
	<div class="anon-login pt-4">
		<div class="box px-4 py-4 mt-1">
			<div class="row justify-content-center">
				<div class="col-lg-4 text-center">
					<template v-if="validating">
						<h3>{{$t('anonLogin.validating')}}</h3>
						<spinner />
					</template>
					<template v-else-if="validated === true">
						<h3>{{$t('terms.success')}}</h3>
						<router-link :to="{name:'admin',params:{lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('terms.dashboard')}}</div></router-link>
					</template>
					<template v-else>
						<h3>{{$t('terms.error')}}</h3>
						<p>{{$t('errors.anErrorOccurredTryAgain')}}</p>
						<router-link :to="{name:'login',params:{lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('terms.login')}}</div></router-link>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import SmallButton from "@/components/buttons/SmallButton";
	import StandardButton from "@/components/buttons/StandardButton";
	import {setCookie} from "@/helperFunctions";
	export default {
		name: "AnonLogin",
		components: {StandardButton, SmallButton, Spinner},
		data(){
			return {
				validating: true,
				validated: false
			}
		},
		methods: {
			checkToken(){
				this.validating = true;
				this.validated = false;
				this.$concreteApi.get(this.$route.params.token,'/account/whoami',true)
				.then(response => {
					if(typeof response.id !== 'undefined' && response.id === Number(this.$route.params.user))
					{
						this.validated = true;
						setCookie('authState',JSON.stringify({
							name: response.name,
							apikey: response.apikey,
							id: response.id
						}),60 * 60 * 24 * 7);
						
						this.$store.commit('concrete/reset');
						this.$store.commit('concrete/setPerformedInitialStateLoad',false);
						this.$router.push({name:'admin',params: {lang: response.locale}});
					}
				})
				.then(() => {
					this.validating = false;
				},() => {
					this.validating = false;
				})
			}
		},
		mounted()
		{
			this.checkToken();
		}
	};
</script>

<style scoped>

</style>
